.kpis {
  padding: 0 32px;
  display: grid;
  grid-template-columns: repeat(3, minmax(328px, 328px));
  row-gap: 16px;
  column-gap: 43px;
}

@media (max-width: 1200px) {
  .kpis {
    grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
    gap: 16px;
  }
}

@media (max-width: 765px) {
  .kpis {
    padding: 0 16px;
    grid-template-columns: repeat(auto-fill, 328px);
  }
}

@media (max-width: 375px) {
  .kpis {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
