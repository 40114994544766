.report {
  background-color: var(--grey-50);
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 99;
}

.report .container {
  margin: 0 auto;
  max-width: 934px;
  background-color: white;
  padding: 100px 80px 140px 40px;
}

.report span {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

/* begin:Header */
.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #061143;
  width: 814px;
}

.print,
.close-icon {
  position: absolute;
  top: -70px;
  cursor: pointer;
}
.print {
  left: 0;
  display: flex;
  align-items: center;
}
.print span {
  margin-left: 16px;
}
.close-icon {
  right: -40px;
  transform: translateY(-25%);
}
.close-icon svg {
  width: 50px;
  height: 50px;
  display: block;
}
.close-icon svg path {
  stroke-width: 1px;
}

.header h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-500);
}

.header h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.18px;
  color: var(--deep-purple);
}

.header .logo {
  height: 45px;
}
.header .logo img {
  height: 100%;
}
/* end:header */

/* Begin:Analytics Cards */

.analytics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 21px;
  margin-top: 36px;
}

/* End:Analytics Cards */

.tables {
  width: 836px;
}

.report footer {
  margin-top: 45px;
  width: 814px;
}
.report footer p {
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
  padding: 10px 0 0 10px;
  border-top: 1px solid var(--deep-purple);
}

@media (max-width: 765px) {
  .report .container {
    padding: 73px 80px 140px 22px;
  }
  .print,
  .header .logo {
    display: none;
  }
  .close-icon {
    right: 40px;
    top: -20px;
  }
  .analytics {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 13px;
  }
}
