.range-slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.range-slider span {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
  margin-right: 8px;
}

.range-slider > div {
  width: 70%;
  position: relative;
}

.range-slider input {
  width: 100%;
  height: 5px;
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.range-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  background: #1ca3ff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}

.range-slider input::-webkit-slider-thumb:hover {
  background: var(--flood-blue-600);
}

.range-slider .percent {
  position: absolute;
  transform: translateX(-50%);
  top: -30px;
  background: var(--flood-blue-600);
  border: 1px solid var(--flood-blue-600);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 0px 9px;
  border-radius: 4px;
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
}

.range-slider input + span.active,
.range-slider input:hover + span {
  opacity: 1;
  visibility: visible;
}
.range-slider .percent::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid var(--flood-blue-600);
}
