.button {
  display: flex;
  align-items: center;
  margin: 10px 0 0 8px;
  cursor: pointer;
}
.button .icon {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--flood-blue-50);
  border-radius: 8px;
  margin-right: 14px;
}
.button span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--flood-blue);
}
