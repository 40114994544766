.team {
  display: flex;
  justify-content: center;
  padding: 86px 0;
  background-color: var(--grey-50);
  position: relative;
}

.team .container {
  width: 539px;
}

.team .container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.team .container .header h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
}

.team .container .header button {
  width: 130px;
  padding: 5px 17px;
}

.team .container .members {
  margin-top: 24px;
}

.team .container .members .member:not(:last-of-type) {
  margin-bottom: 10px;
}

.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

@media (max-width: 765px) {
  .team {
    padding: 26px 0;
  }

  .team .container {
    width: 100%;
    padding: 0 16px;
  }

  .team .container .header {
    padding: 0 8px;
  }
}
