.member {
  background: #ffffff;
  border: 1px solid var(--grey-stroke);
  border-radius: 4px;
  padding: 11px 11px 18px 11px;
  position: relative;
}

.member h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #000000;
}

.member h4 span {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  border-radius: 8px;
  padding: 1.5px 9px;
}

.member h4 .tag {
  color: var(--grey-500);
  background: #d9d9d9;
  margin-left: 6px;
}

.member > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: block;
}

.member > span.email {
  color: var(--grey-500);
}

.member > span.role {
  color: var(--deep-purple);
  margin-top: 8px;
}

.member .dots {
  position: absolute;
  padding: 0 16px;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.member .dots span {
  background: var(--grey-500);
  border: 2px solid var(--grey-500);
  border-radius: 50%;
}

.member .dots span:not(:last-of-type) {
  margin-bottom: 5px;
}

.member .settings {
  width: 156px;
  background: #ffffff;
  border: 1px solid #e5e6eb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateX(100%);
  display: block;
  z-index: 100;
}

.member .settings ul li {
  list-style-type: none;
  padding: 8px 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.member .settings ul li:hover {
  background-color: whitesmoke;
}

.member .settings ul li svg {
  margin-right: 10px;
}

.member .settings ul li:last-of-type {
  color: #ff1d4e;
}

@media (max-width: 810px) {
  .member .settings {
    right: 200px;
  }
}
