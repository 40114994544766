.alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alignment .sides {
  width: 70%;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.alignment .sides > svg {
  cursor: pointer;
}
.alignment .sides > svg.selected line,
.alignment .sides > svg.selected rect {
  transition: 0.2s;
}

.alignment .sides > svg:not(:last-of-type) {
  margin-right: 8px;
}

.alignment .sides > svg.selected line {
  stroke: white;
}

.alignment .sides > svg.selected rect {
  fill: var(--flood-blue);
}
