.btn {
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px -16px 24px;
}
.btn a {
  text-decoration: none;
}
.btn button {
  width: 128px;
  height: 40px;
}
