.bar {
  background: var(--deep-purple);
  margin-top: -58px;
  min-height: 100vh;
  max-height: 100%;
  width: 256px;
  z-index: 98;
  padding: 24px 0;
  transition: 0.3s;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bar::-webkit-scrollbar {
  display: none;
}

.bar.collapsed {
  width: 88px;
}

.bar .logo {
  margin-bottom: 36px;
  padding: 0 32px;
}

.bar.collapsed .logo {
  width: 0;
  overflow: hidden;
}

.bar .arrow {
  position: relative;
  margin-bottom: 24px;
  padding: 0 14px 1px 32px;
  cursor: pointer;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s;
}

.bar.collapsed .arrow {
  padding: 0 36px;
}

.bar .arrow svg {
  transform: rotate(0deg);
  transition: 0.3s;
}
.bar.collapsed .arrow svg {
  transform: rotate(180deg);
}
.bar .arrow:hover svg path {
  stroke: white;
}

.bar > ul {
  overflow: hidden;
}
.bar ul a {
  display: flex;
  align-items: center;
  padding: 19px 32px;
  cursor: pointer;
  text-decoration: none;
  width: 256px;
}
.bar ul a.active {
  background: rgba(255, 255, 255, 0.1);
}
.bar ul a.active span {
  color: white;
}
.bar ul a.active svg path,
.bar ul a.active svg rect {
  stroke: white;
}
.bar ul a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.bar ul a span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #9ba0b4;
  margin-left: 18px;
}
.bar ul a > div {
  display: flex;
}
.bar ul a svg {
  transition: 0.2s;
  width: 24px;
  height: 24px;
}
.bar ul a:hover span {
  color: white;
}
.bar ul a svg path,
.bar ul a svg rect {
  stroke: #9ba0b4;
}
.bar ul a:hover svg path,
.bar ul a:hover svg rect {
  stroke: white;
}
.bar.collapsed ul a span {
  display: none;
}

.sub-links {
  width: 265px;
  display: none;
}
.sub-links.active {
  display: block;
}
.dots {
  position: absolute;
  left: 64px;
}
.bar.collapsed .sub-links.active {
  position: absolute;
  left: 96px;
  margin-top: -70px;
  background: var(--deep-purple);
  border-radius: 8px;
  width: 170px;
  padding: 16px;
}
.bar.collapsed .sub-links.active a {
  padding: 0 !important;
}
.bar.collapsed .sub-links.active a.active {
  background-color: unset !important;
}
.bar.collapsed .sub-links.active a:not(:last-child) {
  margin-bottom: 12px;
}
.bar.collapsed .sub-links.active a span {
  margin-left: 0;
}

.bar.collapsed .sub-links a {
  padding: 12px 0px 12px 32px !important;
}
.bar.collapsed .sub-links a:hover {
  background-color: unset;
}
.sub-links a {
  padding: 12px 0px 12px 60px !important;
}
.bar .sub-links a span {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #9ba0b4;
}
.bar .sub-links.active a span {
  display: inline;
}
.sub-links a svg {
  margin-right: 12px;
}
.sub-links a:hover {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 97;
}
@media (max-width: 1200px) {
  .bar {
    position: fixed;
    width: 0 !important;
    overflow: hidden;
  }

  .bar.collapsed .sub-links.active {
    position: fixed;
  }
}
