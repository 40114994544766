.picker {
  width: 330px;
  position: relative;
}
.card {
  width: 100%;
  max-width: 328px;
  background-color: white;
  border: 1px solid #e5e6eb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: relative;
  cursor: pointer;
}
.card.active {
  border-radius: 8px 8px 0px 0px;
}

.card span {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--deep-purple);
  margin: 0 7px;
}

.card.active > svg:last-of-type {
  transform: rotate(180deg);
}

.dropdown {
  position: absolute;
  top: 43px;
  z-index: 1;
  width: calc(100% - 2px);
  background-color: white;
  box-shadow: 0px 4px 8px rgba(191, 120, 120, 0.12), 0px 12px 16px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 16px 16px;
  padding: 24px 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 99;
  border: 1px solid #e5e6eb;
  border-top: 0;
}
.dropdown.active {
  max-height: 2000px;
  opacity: 1;
  visibility: visible;
}
.dropdown h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--deep-purple);
  margin-bottom: 16px;
  padding: 0 16px;
}
.dropdown > svg:last-of-type {
  position: absolute;
  top: 24px;
  right: 22px;
  cursor: pointer;
}
.dropdown ul li {
  list-style-type: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--deep-purple);
  padding: 16px;
  cursor: pointer;
}
.dropdown ul li:hover {
  background-color: rgb(238, 238, 238);
}
.dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #e5e6eb;
}
.overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 98;
}
@media (max-width: 765px) {
  .date-picker {
    width: 100%;
    max-width: 328px;
  }
}
