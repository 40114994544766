.logo-popup {
  z-index: 99;
  width: 390px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  height: 440px;
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translateX(-50%);
}

.logo-popup .main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-popup .header {
  padding: 21px 18px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-popup .header span {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #061143;
}

.logo-popup .header svg {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.logo-popup .header svg path {
  stroke: black;
}

.logo-popup .editor {
  width: 232px;
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-popup .editor canvas {
  height: 232px !important;
  width: 232px !important;
}

.logo-popup .range-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 232px;
  margin: 21px 0;
}

.logo-popup .range-slider input {
  width: 100%;
  margin: 0 16px;
  height: 5px;
  /* background: linear-gradient(to right, #82cfd0 0%, #82cfd0 50%, #fff 50%, #fff 100%); */

  border-radius: 8px;

  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.logo-popup .range-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  background: #1ca3ff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  cursor: pointer;
}

.logo-popup button {
  width: 235px;
}

@media (max-width: 765px) {
  .logo-popup {
    overflow-y: scroll;
    width: calc(100% - 16px);
    height: unset;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 28px;
  }

  .logo-popup::-webkit-scrollbar {
    display: none;
  }

  .logo-popup .editor {
    width: 100%;
    height: unset;
  }

  .logo-popup .editor canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .logo-popup button {
    width: 328px;
  }
}
@media (max-width: 360px) {
  .logo-popup .range-slider {
    width: 100%;
    padding: 0 18px;
  }
  .logo-popup button {
    width: calc(100% - 16px);
  }
}
