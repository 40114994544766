.details {
  padding: 24px;
  width: 100%;
}
.details > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-text);
}
.details h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: var(--deep-purple);
  margin-top: 8px;
}
.info {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
}
.info > div span {
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-text);
}
.info > div h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var(--deep-purple);
}
.table {
  width: 1200px;
  margin: 60px 0 0 20px;
}
@media (min-width: 1200px) {
  .table {
    width: 100%;
    max-width: 1600px;
  }
}
.table .selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table .selector span {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-text);
  padding-bottom: 18px;
  border-bottom: 1px solid rgba(11, 21, 152, 0.2);
  cursor: pointer;
}
.table .selector span:hover,
.table .selector span.active {
  border-bottom: 2px solid #1ca3ff;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.links a {
  text-decoration: none;
}

.links button {
  width: unset;
  padding: 2px 12px;
  font-size: 12px;
}

@media (max-width: 765px) {
  .details {
    padding: 24px 16px;
  }
}
