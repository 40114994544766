.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 16px;
}

.wrapper .main {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 40px;
}

.wrapper .grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(115px, 1fr));
  gap: 4px;
}

.wrapper .asset.cover {
  position: relative;
  width: 428px;
  height: 340px;
  margin-bottom: 8px;
}

.wrapper .grid > .asset {
  position: relative;
  width: 140px;
  height: 140px;
}

.wrapper .asset img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #00000080;
}

.wrapper .asset svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.wrapper .asset .overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #00000080;
}

.wrapper .asset.video::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #ff1d4d;
  opacity: 0.75;
}

.wrapper .empty,
.wrapper .empty .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.wrapper .empty {
  height: 100%;
  justify-content: space-between;
}

.wrapper .empty .text h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #061143;
  margin-top: 40px;
}
.wrapper .empty .text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #9fa2af;
}

.wrapper .details {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  max-width: 354px;
  width: 100%;
}

.wrapper .details h1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: left;
  color: #061143;
  text-transform: uppercase;
}
.wrapper .details .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .details .guests a {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #1ca3ff;
  text-decoration: none;
}

.wrapper .details .media {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper .details .media p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #061143;
}

.wrapper .details .media > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.wrapper .details .media > div a {
  display: flex;
  align-items: center;
}

.wrapper .details button {
  width: 100%;
  margin-top: 76px;
}

.wrapper .details .status {
  margin: 30px 0 20px;
}

.wrapper .details .status p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: #6f7285;
}

.wrapper .details .status .outer-bar {
  height: 4px;
  margin-top: 8px;
  border-radius: 40px;
  background-color: #e5e6eb;
}

.wrapper .details .status .inner-bar {
  height: 100%;
  width: 20%;
  transition: 0.3s;
  border-radius: 40px;
}

@media screen and (max-width: 900px) {
  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 4px 226px;
  }

  .wrapper .media {
    width: 100%;
  }

  .wrapper .grid {
    grid-template-columns: repeat(3, minmax(60px, 1fr));
  }

  .wrapper .grid .asset {
    width: 100%;
    height: unset;
    aspect-ratio: 1;
  }

  .wrapper .asset.cover {
    width: 100%;
  }

  .wrapper .details {
    position: fixed;
    padding: 20px 14px;
    background-color: white;
    bottom: 0;
    top: unset;
    max-width: unset;
    z-index: 1;
  }

  .wrapper .details button {
    margin-top: 20px;
  }
}
