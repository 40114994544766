.bar {
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  margin-left: 78px;
  transition: 0.3s;
  min-height: 56px;
}

.bar.sidebar-opened {
  margin-left: 256px;
}

.bar > h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--deep-purple);
}

.bar > svg {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .bar {
    margin-left: 0;
    padding: 8px 22px;
  }

  .bar > h4 {
    display: flex;
    align-items: center;
    transition: 1s;
  }

  .bar > h4 a {
    margin-right: 24px;
    display: flex;
    align-items: center;
  }
}
