.popup {
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 343px;
}

.popup h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--deep-purple);
  padding: 22px 16px 0;
}

.popup p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-text);
  margin: 16px 0 24px;
  padding: 0 16px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1px;
}

.btns button {
  width: 50% !important;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 12px 24px;
  width: 163px;
  height: 48px;
  cursor: pointer;
}

.btns button:first-of-type {
  color: var(--deep-purple);
  background: #e5e6eb;
  border-radius: 0 0 0 4px;
}

.btns button:last-of-type {
  background: var(--flood-blue);
  color: white;
  border-radius: 0 0 4px 0;
  transition: 0.3s;
}
.btns button:last-of-type:hover,
.btns button:last-of-type.submitting {
  background-color: #1b94e5;
}

@media (max-width: 765px) {
  .popup {
    max-width: calc(100% - 32px);
    margin-top: 20px;
    top: 68px;
    transform: translateX(-50%);
  }
}
