.main {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 343px;
  padding: 22px 16px;
  left: 50%;
  position: absolute;
  top: 68px;
  transform: translateX(-50%);
  z-index: 99;
}

.main .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.main .header svg {
  cursor: pointer;
}

.main h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.main .track {
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 10px;
  position: relative;
  overflow: hidden;
}

.main .track.selected-track {
  border: 1px solid var(--flood-blue);
}

.main .track:not(:first-of-type) {
  margin-top: 11px;
}
.main .track:last-of-type {
  margin-bottom: 17px;
}

.main .track audio {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.main .track > div {
  display: flex;
  align-items: center;
}
.main .track > div .img {
  background: var(--deep-purple);
  border-radius: 50%;
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  cursor: pointer;
}

.main .track > div .img:hover svg path,
.main .track > div .img:hover svg rect {
  fill: var(--flood-blue-600);
}

.main .track > div {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}
.main .track > div h4 {
  font-weight: 500;
  width: 200px;
  word-break: break-all;
}
.main .track > div span {
  font-weight: 400;
  color: var(--grey-500);
}

.main .track .check {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  background: #ffffff;
  border: 1px solid var(--grey-200);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.main .track.selected-track .check {
  background: #98ccfd;
  border: 1px solid var(--flood-blue-600);
}

.main .track .progress-bar {
  position: absolute;
  background: var(--flood-blue);
  border-radius: 8px;
  left: 0;
  bottom: -1px;
  height: 5px;
}

.main > label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--flood-blue);
  cursor: pointer;
}

.main > button {
  width: 100%;
  margin-top: 44px;
}
