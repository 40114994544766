.dropdown {
  position: relative;
  margin-top: 24px;
  cursor: pointer;
}

.dropdown .input {
  border-radius: 8px;
  background: var(--grey-100);
  font-size: 14px;
  color: var(--deep-purple);
  line-height: 20px;
  width: 100%;
  height: 56px;
  padding: 26px 16px 10px 16px;
  cursor: pointer;
}
.dropdown.active .input {
  border-radius: 8px 8px 0 0;
}

.dropdown.active .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
  cursor: auto;
}

.dropdown .arrows {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  pointer-events: none;
}

.dropdown .arrows svg:last-of-type {
  transform: rotate(180deg);
  margin-top: 2px;
}

.dropdown span {
  position: absolute;
  left: 16px;
  top: 6px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--grey-500);
  transition: 0.3s;
  pointer-events: none;
}

.dropdown .options {
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  z-index: 99;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border: 1px solid var(--grey-100);
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}

.dropdown.active .options {
  max-height: 1000px;
  visibility: visible;
  opacity: 1;
}

.dropdown .options div {
  background: white;
  font-size: 14px;
  color: var(--deep-purple);
  line-height: 20px;
  width: 100%;
  padding: 16px;
  cursor: pointer;
}

.dropdown .options div:hover {
  background-color: var(--grey-100);
}
