.top-bar {
  display: flex;
  background: #ffffff;
  border: 1px solid var(--grey-stroke);
  padding: 24px;
  border-right: 0;
  border-left: 0;
}

.albums {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.albums .list {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 800px;
}

.album {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
}

.album .details {
  display: flex;
  align-items: center;
  gap: 20px;
}

.album .details img {
  height: 80px;
  width: 80px;
  border-radius: 8px 0px 0px 8px;
}

.album .details .title a {
  color: #1ca3ff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.09px;
  text-decoration: none;
}

.album .details .date {
  color: #6f7285;
  font-size: 12px;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.08px;
}

.album .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.album .status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.album .status .circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.album .status p {
  color: #6f7285;
  font-size: 12px;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.09px;
}

.album .btns div {
  cursor: pointer;
}

@media (max-width: 765px) {
  .top-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .albums .list {
    padding: 0 16px;
  }

  .album {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .album .right {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .top-bar > div:nth-child(1) {
    width: 100%;
  }
}
