.reviews {
  min-height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  padding: 64px 0;
}

.reviews .container {
  width: 540px;
}

.reviews h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
  margin-bottom: 21px;
}

.review {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  padding: 8px 21px 8px 15px;
}

.review:not(:last-of-type) {
  margin-bottom: 10px;
}

.review {
  font-size: 14px;
  line-height: 20px;
}

/* .review .details{
  max-width: 300px;
} */

.review a {
  display: block;
  color: var(--flood-blue);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: initial;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.review a:last-of-type {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.review a:last-of-type svg{
  margin-right: 8px;
}

.review h4 {
  font-weight: 500;
  letter-spacing: 0.005em;
  color: var(--deep-purple);
  margin: 5px 0;
}

.review p,
.review span {
  font-weight: 400;
  color: var(--grey-500);
}

.review > div {
  display: flex;
  align-items: center;
}
.review > div > div:first-of-type {
  display: flex;
  height: 100%;
}

.review svg {
  margin-right: 11px;
}

@media (max-width: 765px) {
  .reviews {
    padding: 28px 0;
  }
  .reviews .container {
    width: 100%;
    padding: 0 16px;
  }

  /* .review a{
    max-width: 150px;
  } */
}
