.date-pickers {
  display: flex;
  background: #ffffff;
  border: 1px solid var(--grey-stroke);
  padding: 24px;
  border-right: 0;
  border-left: 0;
}

.date-pickers > div:first-of-type {
  margin-right: 43px;
}

@media (max-width: 840px) {
  .date-pickers > div:first-of-type {
    margin-right: 24px;
  }
  .date-pickers > div:nth-child(1),
  .date-pickers > div:nth-child(3) {
    width: 300px;
  }
}
@media (max-width: 765px) {
  .date-pickers {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .date-pickers > div:nth-child(1),
  .date-pickers > div:nth-child(3) {
    width: 328px;
  }
  .date-pickers > div:first-of-type {
    margin: 0 0 20px 0;
  }

  .analytics > span {
    margin: 26px 16px 14px 16px;
  }

  .analytics .kpis {
    padding: 0 16px;
    grid-template-columns: repeat(auto-fill, 328px);
  }
}
@media (max-width: 375px) {
  .date-pickers > div:nth-child(1),
  .date-pickers > div:nth-child(3) {
    width: 100%;
  }
  .analytics .kpis {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
