.file {
  width: 100%;
  max-width: 310px;
  background: #f9f9fb;
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  padding: 7px 50px 7px 8px;
  display: flex;
  align-items: center;
  position: relative;
}

.file .thumbnail {
  width: 46px;
  height: 46px;
}
.file .thumbnail img {
  width: 46px;
  height: 46px;
  object-fit: cover;
  border-radius: 8px;
}

.file .text {
  margin-left: 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
}

.file .text h4 {
  font-weight: 500;
  color: var(--deep-purple);
  word-break: break-word;
}
.file .text span {
  font-weight: 400;
  color: var(--grey-500);
}

.file .trash-icon {
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  cursor: pointer;
}

.uploading {
  display: flex;
  align-items: center;
  margin-left: 13px;
}

.uploading span {
  margin-left: 26px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}
