.default-textarea {
  position: relative;
  height: 100%;
}

.textarea {
  background: var(--grey-100);
  border-radius: 8px;
  position: relative;
  display: flex;
  height: 100%;
  border: 2px solid var(--grey-100);
}

.default-textarea.error .textarea {
  border: 2px solid var(--primary-red);
}

.textarea:focus-within {
  border-color: var(--flood-blue);
}


.textarea textarea {
  border-radius: 8px;
  background: var(--grey-100);
  font-size: 14px;
  color: var(--deep-purple);
  line-height: 20px;
  border: none;
  outline: none;
  width: 100%;
  padding: 26px 16px 10px 16px;
  resize: none;
  height: 100% !important;
}

.textarea textarea.disabled {
  color: var(--grey-text);
}

.textarea span {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-placeholder);
  transition: 0.4s;
  pointer-events: none;
}

.textarea textarea:focus ~ span,
.textarea textarea:not(:placeholder-shown) ~ span {
  top: 12px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.textarea textarea::-webkit-outer-spin-button,
.textarea textarea::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textarea textarea[type="number"] {
  -moz-appearance: textfield;
}

.default-textarea .note,
.default-textarea .error-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin-top: 5px;
}

.default-textarea .note {
  color: var(--grey-500);
}

.default-textarea .error-message {
  color: var(--primary-red);
  display: none;
}

.default-textarea.error .error-message {
  display: inline;
}

@media (max-width: 765px) {
  .textarea textarea {
    font-size: 12px;
    line-height: 16px;
  }
}
