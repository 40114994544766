.password-settings {
  padding: 53px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--grey-50);
  position: relative;
  min-height: 100vh;
}

.password-settings .container {
  width: 684px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;
}

.password-settings .container .input {
  max-width: 328px;
  margin-left: 52px;
}

.password-settings .container .input:nth-child(2) {
  margin: 8px 0 80px 52px;
}

.password-settings .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--flood-blue-50);
  padding: 12px 0;
}

.password-settings button {
  width: 319px;
}

.password-changed {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.password-changed h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--flood-blue);
  margin-bottom: 36px;
}

.password-changed svg {
  margin-bottom: 80px;
}

@media (max-width: 765px) {
  .password-settings {
    padding: 0;
    min-height: unset;
  }

  .password-settings .container {
    padding-top: 28px;
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .password-settings .container .input {
    max-width: 100%;
    margin: 0 18px;
  }

  .password-settings .container .input:nth-child(2) {
    margin: 0 18px 80px 18px;
  }

  .password-settings .btn {
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    padding: 12px 18px 186px;
  }

  .password-settings .btn button {
    width: 100%;
  }

  .password-changed h4{
    font-size: 16px;
  }
}
