.kpi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  height: 72px;
  border: 1px solid var(--grey-stroke);
  border-radius: 8px;
  background-color: white;
}

.kpi .svg {
  width: 56px;
  height: 56px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--flood-blue-50);
}

.kpi .svg svg {
  width: 24px;
  height: 24px;
}

.kpi .svg svg path {
  stroke: var(--deep-purple);
}

.kpi .details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.kpi .details h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--deep-purple);
  margin-bottom: 4px;
}

.kpi .details span {
  font-weight: 800;
  font-size: 14px;
  line-height: 120%;
  color: var(--deep-purple);
}
