.details {
  padding: 24px;
  width: 100%;
}
.details > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-text);
}
.details h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: var(--deep-purple);
}
.info {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1200px;
}
.info > div span {
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-text);
}
.info > div h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: var(--deep-purple);
}
.tables {
  width: 100%;
  min-width: 1380px;
  margin-top: 60px;
}

.tables .selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tables .selector span {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-text);
  padding-bottom: 18px;
  border-bottom: 2px solid rgba(11, 21, 152, 0.2);
  cursor: pointer;
}
.tables .selector span:hover,
.tables .selector span.active {
  border-bottom: 2px solid #1ca3ff;
  color: #1ca3ff;
}

.table > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 520px;
}
.table > div h5 {
  color: var(--deep-purple);
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
}

@media (max-width: 765px) {
  .details {
    padding: 24px 16px;
  }
}
