.guest p {
  margin: 9px 0 30px;
}

.guest h3 {
  margin-bottom: 23px;
}

.guest .sliders > div {
  margin-bottom: 21px;
}

.guest .sliders:last-of-type {
  margin: 61px 0 88px;
}
