.error {
  height: calc(100vh - 58px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
}
.error h2 {
  font-size: 240px;
  font-weight: 800;
  line-height: 250px;
  color: var(--flood-blue);
}
.error span {
  font-size: 30px;
  font-weight: 800;
  color: #728293;
  margin-bottom: 20px;
}
.error p {
  font-size: 22px;
  color: #728293;
  max-width: 300px;
  text-align: center;
}
.error > img {
  position: absolute;
  right: 20px;
  bottom: 30px;
  width: 126px;
  height: 40px;
}
@media (max-width: 765px) {
  .error h2 {
    font-size: 140px;
  }
  .error span {
    font-size: 22px;
  }
  .error p {
    max-width: 200px;
    font-size: 16px;
  }
  .error > img {
    width: 90px;
    height: 30px;
  }
}
