.message-settings {
  width: 328px;
  padding: 15px 0 0 0;
}

.message-settings h2 {
  margin-bottom: 13px;
}

.message-settings p {
  margin-bottom: 13px;
}

.message-settings .channel > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--grey-500);
  display: flex;
  justify-content: center;
  position: relative;
}
/* .inputs-wrapper {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transition-delay: 0ms;
  overflow: hidden;
}
.message-settings .inputs-wrapper.show {
  max-height: 200px;
} */
.message-settings .channel > span::after,
.message-settings .channel > span::before {
  content: "";
  position: absolute;
  background-color: #e5e6eb;
  width: 40%;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.message-settings .channel > span::after {
  right: 0;
}
.message-settings .channel > span::before {
  left: 0;
}

.message-settings .channel > div:last-of-type {
  margin-bottom: 24px;
}
.message-settings .channel .switcher {
  margin-top: 10px;
}
.switcher {
  display: flex;
}

.switcher div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.switcher h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

@media (max-width: 765px) {
  .message-settings {
    width: 100%;
    padding: 15px 18px 0 18px;
  }
  .message-settings .channel > span {
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }

  .message-settings .channel > span::after {
    position: unset;
    margin-left: 15px;
    width: 100%;
  }
  .message-settings .channel > span::before {
    display: none;
  }
}
