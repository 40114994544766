.default-input {
  position: relative;
}

.input {
  background: var(--grey-100);
  border-radius: 8px;
  position: relative;
  border: 2px solid var(--grey-100);
  display: flex;
}

.default-input.error .input {
  border: 2px solid var(--primary-red);
}

.input:focus-within {
  border-color: var(--flood-blue);
}

.input input {
  border-radius: 8px;
  background: var(--grey-100);
  font-size: 14px;
  color: var(--deep-purple);
  line-height: 20px;
  border: none;
  outline: none;
  width: 100%;
  padding: 26px 16px 10px 16px;
}

.input input.disabled {
  color: var(--grey-text);
}

.input span {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-placeholder);
  transition: 0.3s;
  pointer-events: none;
}

.input input:focus ~ span,
.input input:not(:placeholder-shown) ~ span {
  top: 12px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input input[type="number"] {
  -moz-appearance: textfield;
}

.default-input .note,
.default-input .error-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin: 3px 0 0 3px;
}

.default-input .note {
  color: var(--grey-placeholder);
}

.default-input .error-message {
  color: var(--primary-red);
  display: none;
}

.default-input.error .error-message {
  display: block;
}
