.analytics {
  width: 100%;
  background-color: var(--grey-50);
  height: 100%;
  min-height: calc(100vh - 57px);
}

.analytics > h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
  margin: 32px 32px 14px 32px;
}

@media (max-width: 765px) {
  .analytics > h2 {
    margin: 26px 16px 14px 16px;
  }
}
