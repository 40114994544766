.uploading-loader {
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  max-width: 310px;
  display: flex;
  align-items: center;
  padding: 8px 21px;
  margin: 22px 0 14px;
}

.uploading-loader span {
  margin-left: 26px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.file {
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  max-width: 310px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  margin: 22px 0 14px;
  position: relative;
  overflow: hidden;
}

.file .img {
  width: 43px;
  height: 43px;
  background: #061143;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  cursor: pointer;
}

.file .img:hover svg path,
.file .img:hover svg rect {
  fill: var(--flood-blue-600);
}

.file > div {
  line-height: 4px;
}

.file span {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: var(--grey-500);
}

.file .progress-bar {
  position: absolute;
  background: var(--flood-blue);
  border-radius: 8px;
  left: 0;
  bottom: -1px;
  height: 5px;
}
