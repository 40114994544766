.default-input {
  position: relative;
}

.input {
  background: var(--grey-100);
  border-radius: 8px;
  position: relative;
  border: 2px solid var(--grey-100);
  display: flex;
}

.default-input.error .input {
  border: 2px solid var(--primary-red);
}

.input:focus-within {
  border-color: var(--flood-blue);
}

.input input {
  border-radius: 8px;
  background: var(--grey-100);
  font-size: 14px;
  color: var(--deep-purple);
  line-height: 20px;
  border: none;
  outline: none;
  width: 100%;
  padding: 26px 16px 10px 0px;
}

.input input.disabled {
  color: var(--grey-text);
}

.input span {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: var(--grey-500);
  transition: 0.3s;
  pointer-events: none;
}

.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input input[type="number"] {
  -moz-appearance: textfield;
}

.input .dropdown {
  padding: 26px 0 10px 10px;
}

.input .dropdown select {
  background-color: unset;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  outline: none;
}

.input .dropdown select::-ms-expand {
  display: none;
}

.default-input .note,
.default-input .error-message {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin: 3px 0 0 3px;
}

.default-input .note {
  color: var(--grey-placeholder);
}

.default-input .error-message {
  color: var(--primary-red);
  display: none;
}

.default-input.error .error-message {
  display: block;
}
