.notification {
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 343px;
  padding: 22px 12px;
}

.notification .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
}

.notification .top h2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--deep-purple);
}

.notification .top svg {
  position: absolute;
  top: -4px;
  right: 0;
  cursor: pointer;
}

.notification > p,
.notification p a {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.notification > p {
  margin-bottom: 10px;
}

.notification.Sms p {
  margin-bottom: 20px;
}

.notification .input {
  margin-bottom: 18px;
}

.notification .textarea {
  margin-bottom: 60px;
}

.notification.Email .textarea {
  height: 221px;
}

.notification.Sms .textarea {
  height: 109px;
}

.notification button {
  width: 100%;
}

@media (max-width: 765px) {
  .notification {
    max-width: calc(100% - 32px);
    margin-top: 20px;
    top: 68px;
    transform: translateX(-50%);
  }
}
