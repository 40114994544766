.table {
  margin-top: 30px;
  border: 1px solid #6f7285;
  border-radius: 10px;
  width: 814px;
  border-spacing: 0;
  overflow: hidden;
}
.table h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--deep-purple);
  padding: 17px 0 10px 17px;
  position: relative;
}
.table h4 span {
  display: inline;
  background: var(--grey-placeholder);
  border: 1px solid var(--grey-placeholder);
  border-radius: 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  padding: 0px 13px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  right: -16px;
  transform: translate(100%, 2px);
}

.table th {
  text-align: start;
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}
.table td {
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

.table tr th:last-child {
  font-weight: 600;
  width: 12%;
}

.table thead tr {
  background: #f9f9fb;
}
.table thead tr th {
  border-top: 1px solid #6f7285;
  color: var(--grey-500);
}

.table thead tr th:first-of-type {
  padding: 7px 15px;
}

.table tbody tr th,
.table tbody tr td {
  padding-top: 18px;
}
.table tbody tr th:first-of-type {
  padding-left: 15px !important;
}
.table tbody tr:last-of-type th,
.table tbody tr:last-of-type td {
  padding: 18px 0 20px 0;
}

.table tfoot tr th:first-child,
.table tfoot tr td:first-child {
  padding: 15px;
}

.table tfoot tr th,
.table tfoot tr td {
  border-top: 1px solid #e5e6eb;
}

.table .note {
  position: relative;
  height: 60px;
}
.table .note p {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
  position: absolute;
  top: 8px;
  left: 16px;
}
.table .note span {
  font-weight: 600;
  display: inline-block;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.orders tbody tr th:nth-child(1) {
  width: 50px;
}
.orders tbody tr th {
  padding-right: 10px;
}
.orders tbody tr th:nth-child(2) {
  max-width: 117px;
  word-break: break-all;
}
.orders tbody tr th:nth-child(3),
.orders tbody tr th:nth-child(5) {
  max-width: 176px;
  word-break: break-all;
}
.orders tbody tr th:nth-child(4) {
  max-width: 80px;
  word-break: break-all;
}

.orders tbody tr th:last-of-type {
  font-weight: 400;
}
.orders tr th:last-child {
  font-weight: 400;
}
