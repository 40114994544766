.report-list {
  background-color: var(--grey-50);
  min-height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
}
.report-list .container {
  width: 539px;

  padding-top: 90px;
}

.report-list .container h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
}

.list {
  margin-top: 46px;
  width: 100%;
  position: relative;
}
.list .marks {
  position: absolute;
  top: -25px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 100px 0 15px;
}
.list .marks span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--grey-500);
}

.list .marks span:first-of-type {
  width: 54%;
}

.list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  background: #ffffff;
  border: 1px solid var(--grey-200);
  border-radius: 4px;
  padding: 0 19px 0 15px;
  margin-bottom: 7px;
  transition: .3s;
}
.list li:hover {
  border: 1px solid #1ca3ff;
}

.list li h4,
.list li span {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #000000;
}
.list li h4 {
  width: 42%;
  font-weight: 500;
}

.list li button {
  width: 65px;
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

@media (max-width: 765px) {
  .report-list .container {
    width: 100%;
    padding: 24px 16px;
  }
}

@media (max-width: 400px) {
  .list .marks {
    padding: 0 86px 0 15px;
  }
  .list li h4 {
    width: 30%;
  }
}
