.home {
  padding: 30px 25px;
  background-color: var(--grey-50);
}

.home .user {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.home .user .logo {
  height: 60px;
  width: 60px;
  margin-right: 20px;
  background-color: #eae6ea;
  border-radius: 50%;
}
.home .user .logo img {
  height: 100%;
  object-fit: contain;
}

.home .user .details h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
}
.home .user .details h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--deep-purple);
  word-break: break-all;
}

.home .cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 328px);
  row-gap: 10px;
  column-gap: 25px;
}

.home .card {
  height: 72px;
  background: #ffffff;
  border: 1px solid var(--grey-200);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.home .card .icon {
  background: #061143;
  border-radius: 7px 0px 0px 7px;
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home .card .icon svg {
  width: 32px;
  height: 32px;
}

.home .card .icon svg path,
.home .card .icon svg rect {
  stroke: white;
}

.home .card h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
  margin-left: 8px;
}

@media (max-width: 836px) {
  .home .cards {
    grid-template-columns: repeat(1, 100%);
  }
}

@media (max-width: 765px) {
  .home {
    padding: 24px 16px;
  }
}
