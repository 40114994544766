.card {
  background: var(--grey-50);
  border: 1px solid var(--grey-stroke);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 18px 15px;
  cursor: pointer;
  transition: 0.2s;
  margin-top: 10px;
}
.card:hover {
  border-color: #1ca3ff;
}

.card div {
  margin-left: 18px;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
}

.card div h4 {
  font-weight: 500;
  color: var(--deep-purple);
}

.card div span {
  font-weight: 400;
  color: var(--grey-500);
}
