.logo {
  width: 100%;
  padding: 20px;
  background: #061143;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 116px;
  height: 116px;
  border-radius: 50%;
}

.logo label {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.2px;
  color: var(--flood-blue);
  margin-top: 13px;
  cursor: pointer;
}

.logo input {
  display: none;
}
@media (max-width: 765px) {
  .logo {
    padding: 18px;
    border-radius: 0;
  }
}
