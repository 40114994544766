.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 90px 16px;
}

.wrapper .main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 320px;
}

.wrapper .main h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 1px;
  margin: 20px 0;
}

.wrapper .main .input {
  margin-bottom: 30px;
  width: 100%;
}

.wrapper .main button {
  width: 100%;
}
