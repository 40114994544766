.quality-picker {
  width: 100%;
  position: relative;
}
.picker {
  width: 100%;
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  position: relative;
  cursor: pointer;
}


.picker span {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--deep-purple);
  margin: 0 7px;
}

.picker.active > svg:last-of-type {
  transform: rotate(180deg);
}

.menu {
  position: absolute;
  top: 46px;
  z-index: 1;
  width: calc(100% - 2px);
  background: #ffffff;
  border: 1px solid var(--grey-200);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 99;
}

.menu.active {
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
}

.menu ul li {
  list-style-type: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--deep-purple);
  padding: 4px;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu ul li span {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(--deep-purple);
}

.menu ul li:hover {
  background-color: rgb(238, 238, 238);
}
.menu ul li:not(:last-child) {
  border-bottom: 1px solid #e5e6eb;
}
.overlay.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 98;
}

@media (max-width: 765px) {
  .quality-picker {
    width: 100%;
    max-width: 100%;
  }
}
