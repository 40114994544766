.card {
  background: var(--flood-blue-50);
  border-radius: 8px;
  padding: 13px 0 21px 15px;
}

.card h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-500);
  margin-bottom: 13px;
}

.card h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.18px;
  color: var(--deep-purple);

  position: relative;
  display: inline;
}
