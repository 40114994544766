.top-bar {
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  border: 1px solid var(--grey-stroke);
  padding: 24px;
  border-right: 0;
  border-left: 0;
}

.details {
  padding: 30px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  max-width: 328px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.form .back-button {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #1ca3ff;
  font-weight: 600;
}

.status span,
.link span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #6f7285;
}

.status p,
.link a {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.status p {
  color: #061143;
}

.link a {
  display: block;
  color: #1ca3ff;
  margin-bottom: 20px;
}

@media screen and (max-width: 765px) {
  .details {
    padding: 30px 16px;
  }
}
