.shop {
  padding: 53px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--grey-50);
  position: relative;
  min-height: 100vh;
}

.shop .container {
  width: 684px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.shop .container .form {
  padding-top: 34px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop .container .form .line {
  background-color: var(--grey-stroke);
  width: 100%;
  height: 1px;
}

.shop-details,
.sales-settings,
.review-settings {
  width: 328px;
}

.shop h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
  margin-bottom: 21px;
}

.shop p,
.shop p a {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
  max-width: 325px;
}

.shop .form {
  position: relative;
}

.shop .form .shop-details {
  margin-bottom: 39px;
}

.shop .form .shop-details .input:last-of-type {
  margin-top: 9px;
}

.shop .form .sales-settings {
  margin: 12px 0 35px 0;
}

.shop .form .sales-settings h2 {
  margin-bottom: 14px;
}

.shop .form .sales-settings > p {
  margin-bottom: 21px;
}

.switcher {
  display: flex;
  align-items: center;
}

.switcher div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.switcher h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

.shop .form .sales-settings .input {
  margin-top: 22px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  margin-right: 15px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-stroke);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: var(--flood-blue);
}

.switch input:checked + .slider:before {
  transform: translateX(36px);
}

/* Rounded sliders */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}

.review-settings {
  padding: 15px 0 50px 0;
}
.review-settings h2 {
  margin-bottom: 13px;
}
.review-settings p {
  margin-bottom: 10px;
}
.review-settings .subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: var(--grey-500);
  display: flex;
  justify-content: center;
  position: relative;
  margin: 24px 0 13px;
}
.review-settings .subtitle::after,
.review-settings .subtitle::before {
  content: "";
  position: absolute;
  background-color: #e5e6eb;
  width: 32%;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.review-settings .subtitle::after {
  right: 0;
}
.review-settings .subtitle::before {
  left: 0;
}
.review-settings .input:not(:last-of-type) {
  margin-bottom: 15px;
}

.inputs-wrapper {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transition-delay: 0ms;
  overflow: hidden;
}
.inputs-wrapper.show {
  opacity: 1;
  visibility: visible;
}

.sales-settings .inputs-wrapper.show {
  max-height: 200px;
  overflow: visible;
}

.review-settings .inputs-wrapper.show {
  max-height: 500px;
}

.shop .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--flood-blue-50);
  padding: 12px 0;
}
.shop button {
  width: 319px;
}

@media (max-width: 765px) {
  .shop {
    padding: 0;
  }

  .shop .container {
    height: 100%;
    max-height: unset;
    border-radius: 0;
  }

  .shop .container::-webkit-scrollbar {
    display: none;
  }

  .shop .container::-webkit-scrollbar-track {
    display: none;
  }

  .shop .container::-webkit-scrollbar-thumb {
    display: none;
  }

  .shop-details,
  .sales-settings,
  .message-settings,
  .review-settings {
    width: 100%;
    padding: 0 18px;
  }
  .review-settings {
    padding: 15px 18px 40px 18px;
  }
  .review-settings .subtitle {
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }

  .review-settings .subtitle::after {
    position: unset;
    margin-left: 15px;
    width: 100%;
  }
  .review-settings .subtitle::before {
    display: none;
  }

  .shop .btn {
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    padding: 16px 18px 24px 18px;
  }

  .shop .btn button {
    width: 100%;
  }
}

@media (max-width: 830px) {
  .shop .container {
    width: 100%;
  }
}
