.payout {
  padding: 74px 24px;
  display: flex;
  justify-content: center;
}
.payout .container {
  width: 684px;
}

.payout h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--deep-purple);
  margin-bottom: 24px;
}
.payout p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  color: var(--grey-text);
  max-width: 528px;
}
.payout .input {
  margin-top: 24px;
}
.payout p > span {
  font-weight: 700;
}
.payout button {
  width: 100%;
  margin-top: 24px;
}
@media (max-width: 1200px) {
  .payout {
    padding: 125px 24px;
  }
  .payout .container {
    width: 100%;
  }
}
@media (max-width: 765px) {
  .payout {
    padding: 24px 16px;
  }
}
