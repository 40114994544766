.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.popup .overlay {
  background-color: rgba(0, 0, 0, 0.329);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup .details {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 1px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 32px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  z-index: 100;
}

.popup p {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: var(--grey-500);
  text-align: center;
  max-width: 320px;
  margin-bottom: 20px;
}

.popup .btns {
  display: flex;
}

.popup .btns button {
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 8px;
  width: 140px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 8px;
}

.popup .btns button:not(:last-of-type) {
  margin-right: 20px;
}

.popup .btns button.cancel {
  background-color: #f4f4f4;
  color: var(--deep-purple);
}
.popup .btns button.cancel:hover {
  background-color: #e7e4e4;
}

.popup .btns button.discard {
  background-color: var(--primary-red);
}
.popup .btns button.discard:hover {
  background-color: #e41c47;
}

@media (max-width: 765px) {
  .popup .details {
    width: 90%;
  }
  .popup .details .btns {
    flex-direction: column;
  }
  .popup .btns button:not(:last-of-type) {
    margin: 0 0 16px 0;
  }
  .popup .btns button:last-of-type {
    margin-top: 0;
  }
}
