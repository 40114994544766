.file > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

.file .selected {
  margin: 10px 0 17px;
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  max-width: 310px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  position: relative;
  overflow: hidden;
}

.file .selected span {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: var(--grey-500);
}

.file .selected img {
  width: 46px;
  height: 46px;
  object-fit: cover;
  margin-right: 13px;
}

.file .selected > div {
  line-height: 4px;
}

.file .options {
  display: flex;
  align-items: center;
  color: var(--grey-200);
}

.file .options label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1ca3ff;
  cursor: pointer;
}

.file .options label:first-of-type {
  margin-right: 8px;
}
.file .options label:last-of-type {
  margin-left: 8px;
}

.file .new-track {
  display: flex;
  align-items: center;
  margin: 10px 0 17px;
  cursor: pointer;
}

.file .new-track .add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: #e0f1ff;
  border-radius: 8px;
  margin: 0 13px 0 4px;
}

.file .new-track span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--flood-blue);
}

.uploading-loader {
  background: var(--grey-50);
  border: 1px solid var(--grey-200);
  border-radius: 8px;
  max-width: 310px;
  display: flex;
  align-items: center;
  padding: 8px 21px;
  margin: 22px 0 14px;
}

.uploading-loader span {
  margin-left: 26px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}
