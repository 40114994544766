.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal .overlay {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 17, 67, 0.6);
  z-index: 98;
}
