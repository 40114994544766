.settings {
  display: flex;
  justify-content: center;
  padding: 86px 0;
  background-color: var(--grey-50);
  position: relative;
}
.settings .container {
  width: 100%;
  max-width: 539px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  padding: 29px 60px 0 60px;
}

.settings h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
}

.settings h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

.settings h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}

.settings p {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
}

.settings .pdf p {
  margin: 9px 0 14px;
}
/* .settings .pdf .upload-button {
  margin: 0;
} */

.settings .banner {
  margin: 32px 0 32px;
}

.settings .banner > p {
  margin: 9px 0;
}

.settings .banner .input {
  max-width: 310px;
}

.settings .container .save {
  margin: 50px -60px 0 -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--flood-blue-50);
  padding: 12px;
}

@media (max-width: 765px) {
  .settings {
    padding: 0;
    min-height: 100vh;
  }

  .settings .container {
    padding: 32px 27px 0 27px;
    max-width: 100%;
  }

  .settings .container .save {
    margin: 51px -27px 56px -27px;
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  }
}
