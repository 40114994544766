.email-settings {
  padding: 53px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--grey-50);
  position: relative;
  min-height: 100vh;
}

.email-settings .container {
  width: 684px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 40px;
}

.email-settings .container h4 {
  margin: 0 0 24px 52px;
}

.email-settings .container .input {
  max-width: 328px;
  margin: 0 0 80px 52px;
}

.email-settings .btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--flood-blue-50);
  padding: 12px 0;
}

.email-settings button {
  width: 319px;
}

.email-changed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.email-changed h4 {
  color: var(--flood-blue);
  margin: 0 0 9px 0 !important;
  font-size: 22px;
}

.email-changed p {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
  margin-bottom: 24px;
}

.email-changed p span {
  font-weight: 600;
}

.email-changed svg {
  margin-bottom: 60px;
}

.email-changed + .btn button {
  border: 1px solid var(--flood-blue);
  background-color: unset;
  color: var(--flood-blue);
}

@media (max-width: 765px) {
  .email-settings {
    padding: 0;
    min-height: unset;
  }

  .email-settings .container {
    padding-top: 28px;
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .email-settings .container h4 {
    margin-left: 18px;
  }

  .email-settings .container .input {
    margin: 0 18px 90px;
    max-width: 100%;
  }

  .email-settings .btn {
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    padding: 12px 18px 186px;
  }

  .email-settings .btn button {
    width: 100%;
  }

  .email-changed{
    padding-top: 24px;
  }

  .email-changed h4 {
    font-size: 16px;
  }

  .email-changed p {
    font-size: 12px;
  }
}
