.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loader svg {
  margin-top: -3em;
}
.loader h2 {
  font-size: 36px;
  color: var(--flood-blue);
  text-align: center;
}
.loader p {
  font-weight: bold;
  font-size: 18px;
  color: var(--flood-blue);
  text-align: center;
}

@media (max-width: 765px) {
  .loader h2 {
    font-size: 24px;
  }
  .loader p {
    font-weight: bold;
    font-size: 14px;
    color: var(--flood-blue);
    text-align: center;
  }
}
