.password {
  width: 100%;
  display: flex;
  align-items: center;

  background: var(--grey-100);
  border: 2px solid var(--grey-100);
  border-radius: 8px;
  position: relative;
}
.password:focus-within {
  border: 2px solid var(--flood-blue);
}
.password.error {
  border: 2px solid var(--primary-red);
}
.password input {
  background: var(--grey-100);
  border: none;
  outline: none;
  width: 100%;
  margin-left: 16px;
  padding: 22px 16px 6px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--deep-purple);
}
.password > span {
  position: absolute;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-placeholder);
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
  pointer-events: none;
}
.password input:focus ~ span,
.password input:not(:placeholder-shown) ~ span {
  top: 16px;
  font-size: 11px;
}
.password .eye {
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
p.error {
  opacity: 0;
  margin: 3px 0 0 3px;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: var(--primary-red);
}
p.error.active {
  opacity: 1;
}
.validation {
  position: absolute;
  width: 100%;
  padding: 16px;
  bottom: -6px;
  transform: translateY(100%);
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 1%), 0px 4px 8px rgb(0 0 0 / 2%), 0px 1px 12px rgb(0 0 0 / 12%);
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 2;
}
.validation.active {
  opacity: 1;
  visibility: visible;
}
.validation > h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
  margin-bottom: 12px;
}
.criteria {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--grey-placeholder);
}
.criteria svg {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
.criteria.check {
  color: var(--success);
}
.criteria.check svg path {
  stroke: var(--success);
}
.criteria:not(:last-child) {
  margin-bottom: 4px;
}
.strength div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.strength div:first-child h5 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--deep-purple);
}
.strength div:first-child span {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  display: none;
}
.strength div:first-child span.weak {
  color: var(--primary-red);
  display: block;
}
.strength div:first-child span.medium {
  color: var(--warning);
  display: block;
}
.strength div:first-child span.good,
.strength div:first-child span.fantastic {
  color: var(--success);
  display: block;
}
.bar {
  position: relative;
  background: var(--grey-stroke);
  border-radius: 40px;
  height: 4px;
  margin-top: 8px;
}
.bar > span {
  position: absolute;
  background: var(--grey-stroke);
  border-radius: 40px;
  height: 4px;
  transition: 0.5s;
}
.bar > span.weak {
  width: 0;
}
.bar > span.medium {
  background-color: var(--warning);
  width: 40%;
}
.bar > span.good {
  background-color: var(--success);
  width: 80%;
}
.bar > span.fantastic {
  background-color: var(--success);
  width: 100%;
}
.hint {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: var(--grey-placeholder);
}
.hint.weak {
  color: var(--primary-red);
}
.hint.medium {
  color: var(--grey-placeholder);
}
.hint.fantastic {
  display: none;
}
