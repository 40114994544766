.popup {
  width: 328px;
  background: rgba(27, 27, 27, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 11px 0px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
}
