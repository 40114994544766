.settings {
  padding: 53px 24px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  background-color: var(--grey-50);
  position: relative;
  min-height: 100vh;
}

.settings .container {
  width: 539px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 29px 0 0;
  height: 100%;
}

.settings h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--deep-purple);
  margin-bottom: 9px;
}
.settings h4 {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-weight: 500;
  color: var(--deep-purple);
}

.settings p {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.01em;
  color: var(--grey-500);
  max-width: 420px;
  margin-bottom: 22px;
}

.settings .line {
  width: 100%;
  height: 1px;
  background-color: var(--grey-200);
}

.video-quality {
  padding: 0 52px 42px 60px;
}

.settings .video-creation {
  padding: 17px 52px 48px 60px;
}

.switcher {
  display: flex;
  align-items: center;
}

.switcher > div {
  line-height: 4px;
}

.switcher span {
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: var(--grey-500);
}

.background-music {
  padding: 18px 52px 35px 60px;
}

.music-selection > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--flood-blue);
  cursor: pointer;
}

.audio-volume {
  padding: 15px 52px 51px 60px;
}

.audio-volume > div:last-of-type {
  margin-top: 20px;
}

.intro-outro {
  padding: 15px 52px 51px 60px;
  display: flex;
  flex-direction: column;
}

.intro-outro > div:not(:last-of-type) {
  margin-bottom: 16px;
}

.intro-outro .connection-line {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.intro-outro .connection-line span {
  color: #d1d3db;
  font-size: 12px;
}

.intro-outro .add {
  display: flex;
  align-items: center;
  color: #1ca3ff;
  font-size: 14px;
  cursor: pointer;
  margin-top: 40px;
}

.intro-outro .add svg {
  margin-right: 10px;
}

.intro-outro .slot {
  position: relative;
}
.intro-outro .remove-slot {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
.intro-outro .remove-slot svg {
  width: 20px;
  height: 20px;
}

.btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e0f1ff;
  border-radius: 0px 0px 4px 4px;
  padding: 12px 0;
}

.video-duration {
  padding: 15px 52px 51px 60px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 765px) {
  .settings {
    padding: 0;
  }

  .settings .container {
    width: 100%;
    height: unset;
  }

  .settings p {
    max-width: 100%;
  }

  .settings .video-quality {
    padding: 0 17px 42px 27px;
  }

  .settings .video-creation {
    padding: 17px 17px 48px 27px;
  }

  .settings .background-music {
    padding: 18px 17px 35px 27px;
  }

  .settings .audio-volume {
    padding: 15px 24px 51px 27px;
  }

  .settings .intro-outro {
    padding: 15px 17px 51px 27px;
  }
  .settings .video-duration {
    padding: 15px 17px 51px 27px;
  }

  .settings .btn {
    background: #ffffff;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    padding: 16px 18px 60px 18px;
  }

  .selected {
    max-width: 100%;
  }
}
