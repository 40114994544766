.color-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.color-picker .colors {
  width: 70%;
  display: flex;
  align-items: center;
}

.color-picker .colors div {
  width: 21px;
  height: 21px;
  border: 1px solid var(--grey-200);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  outline: none;
}

.color-picker .colors div.selected {
  border: 1px solid var(--deep-purple);
}

.color-picker .colors div:not(:last-of-type) {
  margin-right: 12px;
}
