.account {
  padding: 53px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--grey-50);
  position: relative;
  min-height: 100vh;
}

.account .container {
  width: 684px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(209, 211, 219, 0.4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 28px 52px 28px 60px;
}

.account .container .email {
  max-width: 328px;
}

.account .container .email a {
  text-decoration: none;
}

.account .container .email input {
  cursor: pointer;
}

.account .container .email > span {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin-left: 4px;
}

.account .container .email span.error {
  color: var(--primary-red);
}

.account .container .email span.success {
  color: var(--primary-green);
}

.account .password {
  display: flex;
  align-items: center;
  margin-top: 26px;
  cursor: pointer;
  text-decoration: none;
}

.account .password span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--flood-blue);
  margin: 4px 0 0 14px;
}

@media (max-width: 765px) {
  .account {
    padding: 0;
  }

  .account .container {
    padding: 25px 14px 28px 18px;
    width: 100%;
    height: 100vh;
  }

  .account .container .email {
    max-width: 100%;
  }
}
