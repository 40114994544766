.top-bar {
  display: flex;
  background: #ffffff;
  border: 1px solid var(--grey-stroke);
  padding: 24px;
  border-right: 0;
  border-left: 0;
}

.top-bar h4 {
  color: #061143;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.guests {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 16px;
}

.guests .back-button {
  width: 100%;
  max-width: 866px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #1ca3ff;
  font-weight: 600;
  margin-bottom: 20px;
}

.guests .list {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 25px;
  width: 100%;
  max-width: 866px;
}

.guest {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 60px 20px 40px;
}

.guest .details {
  text-decoration: none;
}
.guest .details h4 {
  color: #061143;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
}

.guest .details p {
  color: #6f7285;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.12px;
}

.guest .btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.guest .btns a {
  display: flex;
  align-items: center;
}

.empty svg {
  width: 160px;
  height: 160px;
}
.empty h4 {
  text-align: center;
  color: #061143;
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.guests > button {
  margin-top: 34px;
  width: 100%;
  max-width: 866px;
}

.guests .check-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 34px;
  background-color: #00ee9833;
  border-radius: 8px;
}

.guest .dots {
  position: absolute;
  padding: 0 16px;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.guest .dots span {
  background: var(--grey-500);
  border: 2px solid var(--grey-500);
  border-radius: 50%;
}

.guest .dots span:not(:last-of-type) {
  margin-bottom: 5px;
}

.guest .settings {
  width: 156px;
  background: #ffffff;
  border: 1px solid #e5e6eb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  right: 19px;
  top: 50%;
  transform: translateX(100%);
  display: block;
  z-index: 100;
}

.guest .settings a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #061143;
}

.guest .settings ul li {
  list-style-type: none;
  padding: 8px 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.guest .settings ul li:hover {
  background-color: whitesmoke;
}

.guest .settings ul li svg {
  margin-right: 10px;
}

.guest .settings ul li:last-of-type {
  color: #ff1d4e;
}

.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

@media screen and (max-width: 1250px) {
  .guest .settings {
    right: 180px;
  }
}
@media (max-width: 765px) {
  .guest {
    padding: 20px 40px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}
